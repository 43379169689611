<template>
  <main>
    <div class="status_wrapper">
      <div id="form">
        <form>
          <h1>
            <svg
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              ></path>
            </svg>
            Bonjour <span>{{ form.first_name }} {{ form.last_name }}</span
            ><br />
          </h1>
          <h3>Votre participation à l'opération La Tournée Gagnante 2022</h3>
          <div class="status_title">
            <span>Email : {{ form.email }}</span>
          </div>
          <br />
        </form>
      </div>

      <div v-if="items.length > 0">
        <p
          class="totalpoints"
          v-if="
            items.reduce((point, item) => {
              return point + (item.points || 0);
            }, 0)
          "
        >
          Valeur totale du chèque cadeau :
          <span
            >{{
              items.reduce((point, item) => {
                return point + (item.points || 0);
              }, 0)
            }}€</span
          >
        </p>
      </div>

      <div v-if="visu">
        <div class="step">MES FACTURES</div>
        <div v-if="items.length > 0" class="factures">
          <div class="facture" v-for="item in items" :key="item.invoice">
            <div class="first">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              <a :href="item.invoice" target="_blank">{{
                item.invoice | filename
              }}</a>
            </div>
            <div class="middle">Le {{ item.updatedAt | newDate }}</div>
            <div
              class="lastcol"
              v-if="item.status != 'MISSING' && item.status != 'INVALID'"
            >
              {{ item.status | status }}
              <span v-if="item.points"
                >&nbsp;- Valeur du chèque cadeau {{ item.points }}€</span
              >
            </div>
            <div class="lastcol" v-else>
              {{ item.motif_nc }} - Renvoyez votre facture corrigée
            </div>
          </div>
        </div>
        <Form
          v-if="ttl <= 500"
          id="form"
          :form_id="status_form_id"
          :uid="uid"
          :submission_id="submission_id"
          :server="server"
          v-on:results="getResult"
          v-on:form_submit_loading="loading"
          @on_error="viewError"
          :hideError="hiderror"
        />
      </div>
    </div>
  </main>
</template>
<script>
var consts = require("../config");
import axios from "axios";
import Vue from "vue";
const moment = require("moment");

Vue.filter("filename", function(url) {
  var m = url.toString().match(/.*\/(.+?)\./);
  if (m && m.length > 1) {
    return m[1];
  }
});

Vue.filter("status", function(status) {
  var newstatus;
  if (status == "PENDING") newstatus = "En attente";
  if (status == "VALID") newstatus = "Validé";
  if (status == "MISSING") newstatus = "Echec";
  if (status == "INVALID") newstatus = "Non conforme";
  if (status == "REFUND") newstatus = "Remboursement effectué";
  return newstatus;
});

Vue.filter("newDate", function(date) {
  var m = moment(date);
  return m.format("DD/MM/YYYY");
});

export default {
  name: "Status",
  data: function() {
    return {
      visu: true,
      ttl: undefined,
      form: {},
      items: [],
      declared_button: true,
      hiderror: true,
      submission_id: this.$route.params._id,
      status_form_id: consts.LINE_ITEM_ID,
      server: "https://api.lucien.ai",
      uid: this.$route.params._secu,
      valid: false,
      amount: 0,
    };
  },
  async mounted() {
    try {
      let { data } = await axios.get(
        "https://api.lucien.ai/api/v2/submissions/" +
          this.$route.params._id +
          "?uid=" +
          this.$route.params._secu
      );

      this.form = data;

      this.items = data.lineItems;
      this.ttl = this.items.reduce((point, item) => {
        return point + (item.points || 0);
      }, 0);
    } catch (e) {
      console.log(e);
      this.$swal({
        type: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        confirmButtonColor: "#C94330",
        html:
          "Identifiants non reconnus.<br > Merci de suivre le lien transmit par email.",
      }).then(function() {
        document.location.href = "/";
      });
      return;
    }
  },
  methods: {
    async getFactures() {},
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    getResult: function(result) {
      if (result._id) {
        this.$swal({
          type: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          html:
            "<b>Votre facture a bien été enregistrée. Vous serez informé de sa validation par email.</b>",
        }).then((result) => {
 
            location.reload();
          
        });
      } else {
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          text: "Une erreur est survenue",
        });
      }
    },
    viewError(err) {
      console.log(err);

      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        title: "Désolé, une erreur est survenue.",
        text: "",
      });
    },
  },
};
</script>
